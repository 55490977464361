<template>
  <div class="shuinijianyan">
    <p style="text-align: center;font-size: 30px; width:800px"><Button type="success" @click="downloadFile">下载</Button>
    </p>

    <p style="text-align: center;font-size: 30px; width:800px">水泥检验报告</p>
    <p style="text-align: center; width:800px"><Input v-model="report.tianshu" style="width:150px" /></p>
    <p>&nbsp;</p>
    <table width="800px">
      <tr>
        <td colspan="2">开具时间：<Input v-model="report.kaijushijian" style="width:150px" /></td>
        <td colspan="2">报告编号：<Input v-model="report.baogaobianhao" style="width:150px" /></td>
      </tr>
      <tr>
        <td colspan="3">客户名称：<Input v-model="report.kehumingcheng" style="width:350px" /></td>
        <td>数量<Input v-model="report.shuliang" style="width:150px" /></td>
      </tr>
      <tr>
        <td colspan="4">最终客户：<Input v-model="report.zuizhongkehu" style="width:350px" /></td>
      </tr>
      <tr>
        <td colspan="3">制造商：<Input v-model="report.zhizaoshang" style="width:350px" /></td>
        <td>注册商标：<Input v-model="report.zhuceshangbiao" style="width:100px" /></td>
      </tr>
      <tr>
        <td colspan="3">产地：<Input v-model="report.chandi" style="width:350px" /></td>
        <td>咨询电话：<Input v-model="report.zixundianhua" style="width:100px" /></td>
      </tr>
      <tr>
        <td>执行标准：<Input v-model="report.zhixingbiaozhun" style="width:100px" /></td>
        <td>水泥品种：<Input v-model="report.shuinipinzhong" style="width:100px" /></td>
        <td>代号强度等级：<Input v-model="report.daihaoqiangdudengji" style="width:100px" /></td>
        <td>规格：<Input v-model="report.guige" style="width:100px" /></td>
      </tr>
      <tr>
        <td>窑型：<Input v-model="report.yaoxing" style="width:150px" /></td>
        <td>出厂日期：<Input v-model="report.chuchangriqi" style="width:150px" /></td>
        <td colspan="2">出厂编号：<Input v-model="report.chuchangbianhao" style="width:150px" /></td>

      </tr>
    </table>
    <table width="800px" style="margin-top:-2px">
      <tr style="height: 30px;">
        <td>检验项目</td>
        <td>检验依据</td>
        <td>标准要求</td>
        <td style="width: 300px;">实测值</td>
      </tr>
      <tr v-for="item in report.xiangmu" v-bind:key="item.jianyanxiangmu">
        <td><Input v-model="item.jianyanxiangmu" style="width:150px" /></td>
        <td><Input v-model="item.jianyanyiju" style="width:150px" /></td>
        <td><Input v-model="item.yaoqiu" style="width:150px" /></td>
        <td><Input v-model="item.shicezhi" style="width:250px" /></td>
      </tr>
      <tr>
        <td>检验结论</td>
        <td colspan="3"><Input v-model="report.jianyanjielun" style="width:350px" /></td>

      </tr>
    </table>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      report: {
        tianshu: "(3天)",
        kaijushijian: " 2024年12月05日",
        baogaobianhao: "CLH1XSBG20240002468",
        kehumingcheng: "北京鸿都混凝土有限公司",
        shuliang: "289.64吨",
        zuizhongkehu: "北京鸿都混凝土有限公司",
        zhizaoshang: "北京金隅琉水环保科技有限公司",
        zhuceshangbiao: "金隅",
        chandi: "北京市房山区琉璃河车站前街1号",
        zixundianhua: "010-89382980-2633",
        zhixingbiaozhun: "GB 175-2023",
        shuinipinzhong: "普通硅酸盐水泥",
        daihaoqiangdudengji: "P·042.5",
        guige: "散装",
        yaoxing: "旋窑",
        chuchangriqi: "2024年11月29日",
        chuchangbianhao: "CLH124JYN04N10129",
        xiangmu: [{
          jianyanxiangmu: "三氧化硫(%)",
          jianyanyiju: "GB/T176",
          yaoqiu: "≤3.5",
          shicezhi: "2.72"
        }, {
          jianyanxiangmu: "烧失量(%)",
          jianyanyiju: "GB/T176",
          yaoqiu: "≤5.0",
          shicezhi: "2.13"
        }, {
          jianyanxiangmu: "标准稠度(%)",
          jianyanyiju: "GB/T1346",
          yaoqiu: "",
          shicezhi: "27.4"
        }, {
          jianyanxiangmu: "氧化镁(%)",
          jianyanyiju: "GB/T176",
          yaoqiu: "≤5.0",
          shicezhi: "3.39"
        },{
          jianyanxiangmu: "初凝时间(min)",
          jianyanyiju: "GB/T1346",
          yaoqiu: "≥45",
          shicezhi: "233"
        }, {
          jianyanxiangmu: "氯离子(%)",
          jianyanyiju: "GB176",
          yaoqiu: "≤0.060",
          shicezhi: "0.043"
        }, {
          jianyanxiangmu: "3天抗折强度(MPa)",
          jianyanyiju: "GB/T17671",
          yaoqiu: "≥4.0",
          shicezhi: "5.6,5.7,6.1 平均值:5.8"
        },{
          jianyanxiangmu: "终凝时间(min)",
          jianyanyiju: "GB/T1346",
          yaoqiu: "≤600",
          shicezhi: "294"
        },{
          jianyanxiangmu: "3天抗压强度(MPa)",
          jianyanyiju: "GB/T17671",
          yaoqiu: "≥17.0",
          shicezhi: "26.7,26.6,25.8,25.6,25.6,26.1 平均值:26.1"
        }, {
          jianyanxiangmu: "28天抗折强度(MPa)",
          jianyanyiju: "GB/T17671",
          yaoqiu: "≥6.5",
          shicezhi: "/"
        },  {
          jianyanxiangmu: "28天抗压强度(MPa)",
          jianyanyiju: "GB/T17671",
          yaoqiu: "≥42.5",
          shicezhi: "/"
        }, {
          jianyanxiangmu: "混合材品种及掺量",
          jianyanyiju: "GB/T 12960",
          yaoqiu: "6～20%",
          shicezhi: "矿渣粉14.00%+石灰石2.00%"
        }, {
          jianyanxiangmu: "石膏品种及掺量",
          jianyanyiju: "/",
          yaoqiu: "",
          shicezhi: "脱硫石膏5.5%"
        }, {
          jianyanxiangmu: "助磨剂品种及掺量",
          jianyanyiju: "/",
          yaoqiu: "",
          shicezhi: "JY-GA-3Y  0.035%"
        }, {
          jianyanxiangmu: "安定性(沸煮法)",
          jianyanyiju: "GB/T1346",
          yaoqiu: "合格",
          shicezhi: "合格"
        },{
          jianyanxiangmu: "45μm筛余细度(%)",
          jianyanyiju: "GB/T1345",
          yaoqiu: "≥5.0",
          shicezhi: "6.3"
        }],
        jianyanjielun: "所检各项指标符合GB 175-2023《通用硅酸盐水泥》42.5普通硅酸盐水泥标准。"
      }
    }
  },
  methods: {
    downloadFile() {
      this._downloadFile('/cement/excel/export', this.report)
    },
    _downloadFile(url, params) {
      axios.post(url, params,
      {
        responseType: 'blob' // 设置响应数据类型
      }).then(res => {
        
        let url = window.URL.createObjectURL(res.data)
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', 'result.xlsx') // 自定义下载文件名（如exemple.txt）
        document.body.appendChild(link)
        link.click()


      });
    }
  }
}
/**
 * 下载文件
 */

</script>
<style>
.shuinijianyan {
  width: 1000px;
  padding: 50px;
}

.shuinijianyan table,
th,
td {
  border: 2px solid rgb(55, 55, 55);
  border-collapse: collapse;
  /* 移除单元格之间的间隔 */
}

.shuinijianyan td {
  padding-left: 5px;
}
</style>