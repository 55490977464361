<template>
  <div class="shuinijianyan">
    <p style="text-align: center;font-size: 30px; width:800px"><Button type="success" @click="downloadFile">下载</Button>
    </p>

    <p style="text-align: center;font-size: 30px; width:800px">水泥检验报告</p>
    <p style="text-align: center; width:800px"><Input v-model="report.tianshu" style="width:150px" /></p>
    <p>&nbsp;</p>
    <table width="800px">
      <tr>
        <td colspan="2">开具时间：<Input v-model="report.kaijushijian" style="width:150px" /></td>
        <td colspan="2">报告编号：<Input v-model="report.baogaobianhao" style="width:150px" /></td>
      </tr>
      <tr>
        <td colspan="3">客户名称：<Input v-model="report.kehumingcheng" style="width:350px" /></td>
        <td>数量<Input v-model="report.shuliang" style="width:150px" /></td>
      </tr>
      <tr>
        <td colspan="4">最终客户：<Input v-model="report.zuizhongkehu" style="width:350px" /></td>
      </tr>
      <tr>
        <td colspan="3">制造商：<Input v-model="report.zhizaoshang" style="width:350px" /></td>
        <td>注册商标：<Input v-model="report.zhuceshangbiao" style="width:100px" /></td>
      </tr>
      <tr>
        <td colspan="3">产地：<Input v-model="report.chandi" style="width:350px" /></td>
        <td>咨询电话：<Input v-model="report.zixundianhua" style="width:100px" /></td>
      </tr>
      <tr>
        <td>执行标准：<Input v-model="report.zhixingbiaozhun" style="width:100px" /></td>
        <td>水泥品种：<Input v-model="report.shuinipinzhong" style="width:100px" /></td>
        <td>代号强度等级：<Input v-model="report.daihaoqiangdudengji" style="width:100px" /></td>
        <td>规格：<Input v-model="report.guige" style="width:100px" /></td>
      </tr>
      <tr>
        <td>窑型：<Input v-model="report.yaoxing" style="width:150px" /></td>
        <td>出厂日期：<Input v-model="report.chuchangriqi" style="width:150px" /></td>
        <td colspan="2">出厂编号：<Input v-model="report.chuchangbianhao" style="width:150px" /></td>

      </tr>
    </table>
    <table width="800px" style="margin-top:-2px">
      <tr style="height: 30px;">
        <td>检验项目</td>
        <td>检验依据</td>
        <td>标准要求</td>
        <td style="width: 300px;">实测值</td>
      </tr>
      <tr v-for="item in report.xiangmu" v-bind:key="item">
        <td><Input v-model="item.jianyanxiangmu" style="width:150px" /></td>
        <td><Input v-model="item.jianyanyiju" style="width:150px" /></td>
        <td><Input v-model="item.yaoqiu" style="width:150px" /></td>
        <td><Input v-model="item.shicezhi" style="width:250px" /></td>
      </tr>
      <tr>
        <td>检验结论</td>
        <td colspan="3"><Input v-model="report.jianyanjielun" style="width:350px" /></td>

      </tr>
    </table>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      report: {
        tianshu: "(3天)",
        kaijushijian: "2024年11月25日",
        baogaobianhao: "CZL1XSBG20240007885",
        kehumingcheng: "北京鸿都混凝土有限公司",
        shuliang: "33.55吨",
        zuizhongkehu: "北京鸿都混凝土有限公司",
        zhizaoshang: "涿鹿金隅水泥有限公司",
        zhuceshangbiao: "金隅",
        chandi: "河北省张家口市涿鹿县卧佛寺镇大斜阳村",
        zixundianhua: "0313-6759310",
        zhixingbiaozhun: "GB175-2023",
        shuinipinzhong: "普通硅酸盐水泥",
        daihaoqiangdudengji: "P·042.5",
        guige: "散装",
        yaoxing: "旋窑",
        chuchangriqi: "2024年03月13日",
        chuchangbianhao: "CZL124JYN04N10568",
        xiangmu: [{
          jianyanxiangmu: "烧失量(%)",
          jianyanyiju: "GB175-2023",
          yaoqiu: "≤5",
          shicezhi: "3.10"
        }, {
          jianyanxiangmu: "氧化镁(%)",
          jianyanyiju: "GB175-2023",
          yaoqiu: "≤5",
          shicezhi: "3.79"
        }, {
          jianyanxiangmu: "三氧化硫(%)",
          jianyanyiju: "GB175-2023",
          yaoqiu: "≤3.5",
          shicezhi: "1.94"
        }, {
          jianyanxiangmu: "氯离子(%)",
          jianyanyiju: "GB175-2023",
          yaoqiu: "≤0.06",
          shicezhi: "0.029"
        }, {
          jianyanxiangmu: "试饼法安定性",
          jianyanyiju: "GB/T1346-2011",
          yaoqiu: "合格",
          shicezhi: "合格"
        }, {
          jianyanxiangmu: "45μm筛余细度(%)",
          jianyanyiju: "GB/T1345",
          yaoqiu: "≥5.0",
          shicezhi: "5.7"
        }, {
          jianyanxiangmu: "标准稠度(%)",
          jianyanyiju: "GB/T1346-2011",
          yaoqiu: "/",
          shicezhi: "28.6"
        }, {
          jianyanxiangmu: "初凝时间(min)",
          jianyanyiju: "GB/T1346-2011",
          yaoqiu: "≥45",
          shicezhi: "193"
        }, {
          jianyanxiangmu: "终凝时间(min)",
          jianyanyiju: "GB/T1346-2011",
          yaoqiu: "≤600",
          shicezhi: "253"
        }, {
          jianyanxiangmu: "3天抗折强度(MPa)",
          jianyanyiju: "GB175-2023",
          yaoqiu: "≥4.0",
          shicezhi: "4.7,5.2,5.0 平均值:5.0"
        }, {
          jianyanxiangmu: "3天抗压强度(MPa)",
          jianyanyiju: "GB175-2023",
          yaoqiu: "≥17.0",
          shicezhi: "29.5,29.9,30.0,29.8,29.0,29.9 平均值:29.7"
        }, {
          jianyanxiangmu: "28天抗折强度(MPa)",
          jianyanyiju: "GB175-2023",
          yaoqiu: "≥6.5",
          shicezhi: "/"
        }, {
          jianyanxiangmu: "28天抗压强度(MPa)",
          jianyanyiju: "GB175-2023",
          yaoqiu: "≥42.5",
          shicezhi: "/"
        }, {
          jianyanxiangmu: "混合材品种及掺量",
          jianyanyiju: "GB/T12960-2019",
          yaoqiu: "≥6且≤20",
          shicezhi: "粉煤灰14%矿粉4%"
        }, {
          jianyanxiangmu: "石膏品种及掺量",
          jianyanyiju: "GB/T21371-2008",
          yaoqiu: "/",
          shicezhi: "脱硫石膏5%"
        }, {
          jianyanxiangmu: "助磨剂品种及掺量",
          jianyanyiju: "GB/T26748-2011",
          yaoqiu: "/",
          shicezhi: "醇类0.1%"
        }],
        jianyanjielun: "所检各项指标符合GB175-2023标准"
      }
    }
  },
  methods: {
    downloadFile() {
      this._downloadFile('/cement/excel/export', this.report)
    },
    _downloadFile(url, params) {
      axios.post(url, params,
      {
        responseType: 'blob' // 设置响应数据类型
      }).then(res => {
        
        let url = window.URL.createObjectURL(res.data)
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', 'result.xlsx') // 自定义下载文件名（如exemple.txt）
        document.body.appendChild(link)
        link.click()


      });
    }
  }
}
/**
 * 下载文件
 */

</script>
<style>
.shuinijianyan {
  width: 1000px;
  padding: 50px;
}

.shuinijianyan table,
th,
td {
  border: 2px solid rgb(55, 55, 55);
  border-collapse: collapse;
  /* 移除单元格之间的间隔 */
}

.shuinijianyan td {
  padding-left: 5px;
}
</style>